/* 主题模板定制 */
:root:root {
    --adm-color-primary: #01C288;
    --adm-color-success: #00b578;
    --adm-color-warning: #ff8f1f;
    --adm-color-danger: #ff3141;

    --adm-color-white: #ffffff;
    --adm-color-text: #333333;
    --adm-color-text-secondary: #666666;
    --adm-color-weak: #999999;
    --adm-color-light: #cccccc;
    --adm-color-border: #eeeeee;
    --adm-color-box: #f5f5f5;
    --adm-color-background: #ffffff;
    

    --adm-font-size-main: var(--adm-font-size-5);
  }
