
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
}

.top {
    flex: 0;
    border-bottom: solid 1px var(--adm-color-border);
}

.body {
    flex: 1;
    display: flex;
}

.bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
    /* padding-bottom: 6px; */
}

.row_right {
    width: 100%;
    display: flex;
    justify-content: end;
}

.row_left {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.row_center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.col {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.row_space_small {
    width: 100%;
    height: 8px;
}

.row_space_middle {
    width: 100%;
    height: 16px;
}

.row_space_big {
    width: 100%;
    height: 32px;
}

.mask_content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
}

.mask_content .left, .right {
    flex: 1;
    height: 100%;
}

.mask_content .left, .right {
    opacity: 0.5;
    background-color: #000000;
}

.mask_content .center {
    display: flex;
    flex-direction: column;
}

.mask_content .center .one, .three {
    opacity: 0.5;
    background-color: #000000;
}

.camera_ops {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.startLine {
    margin-top: 12px;
    margin-left: 12px;
}

.resultBG {
    width: 100%;
    flex: 1;
    background-color: #F6F6F6;
    position: relative;
    top: 64px;
}

.resultBG .triangle {
    width: 0;
    height: 0;
    border-left: 100vw solid #FFFFFF;
    border-bottom: 20vw solid transparent;
}

.resultBG .pic {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -64px;
}

.resultBG .title {
    width: 100%;
    display: flex;
    /* justify-content: center;
    position: absolute;
    line-height: 24px; */
}

.resultBG .picTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    line-height: 24px;
}

.resultBG2 {
    width: 100%;
    flex: 1;
    /* background-color: #F6F6F6; */
    position: relative;
    top: 64px;
}

.resultBG2 .pic {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -64px;
}

.resultTab {
    padding: 8px 8px 16px 12px;
    background-color: #F0F0F0;
    border-radius: 10px;
    margin-bottom: 8px;
}

.resultTab .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
}

.resultTab .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
}

.courseTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 41px;
    vertical-align: top;
}

.courseTitle2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 41px;
    vertical-align: top;
}

.iframe {
    border: none;
    overflow-y: scroll;
}
.iframe ::-webkit-scrollbar {
    display: none;
}

.textP {
    text-indent: 26px;
    line-height: 32px;
    margin-left: 16px;
    margin-right: 16px;
}

.textP .black {
    font-weight: bold;
}

.blackText {
    font-weight: bold;
}

.historyContent {
    background: #F6F6F6;
    border-radius: 10px;
    opacity: 1;
    height: 60px;
    /* align-items: center; */
}

.braft-output-content {
    padding: 8px 24px 8px 24px;
}

.braft-output-content p {
    min-height: 1em
}

.braft-output-content .image-wrap img {
    max-width: 100%;
    height: auto
}

.braft-output-content .video-wrap video {
    max-width: 100%;
    display: flex;
    justify-content: center;
    height: auto
}

.braft-output-content .audio-wrap {
    max-width: 100%;
    display: flex;
    justify-content: center;
    height: auto
}

.braft-output-content ul,
.braft-output-content ol {
    margin: 16px 0;
    padding: 0
}

.braft-output-content blockquote {
    margin: 0 0 10px 0;
    padding: 15px 20px;
    background-color: #f1f2f3;
    border-left: solid 5px #ccc;
    color: #666;
    font-style: italic
}

.braft-output-content pre {
    max-width: 100%;
    max-height: 100%;
    margin: 10px 0;
    padding: 15px;
    overflow: auto;
    background-color: #f1f2f3;
    border-radius: 3px;
    color: #666;
    font-family: monospace;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    word-wrap: break-word;
    white-space: pre-wrap
}

.braft-output-content pre pre {
    margin: 0;
    padding: 0
}

.ho-tabs .adm-tabs .adm-tabs-header {
    border-bottom: none;
}

.d_button_iphone button {
    width: 100%;
    background: linear-gradient(to left, rgba(1, 194, 139, 0.2), rgba(67, 207, 124, 1));
}

.d_button_android button {
    width: 100%;
    background: linear-gradient(to left, rgba(0, 225, 255, 0.2), rgba(0, 166, 255, 1));
}

.hoText_title {
    text-align: center;
    font-weight: 500; 
    font-size: 18pt;
}

.hoText_desc {
    text-align: center;
    font-size: 10pt;
    color: #8c8c8c;
}

.homeBG {
    width: 100%;
    background-color: #F6F6F6;
    position: relative;
}

.homeBG .pic {
    width: 100%;
    display: flex;
    justify-content: center;
}

.homeBG .title {
    position: absolute;
    top: 20%;
    left: 55%;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 16pt;
}

.homeBG .desc {
    display: flex;
    position: absolute;
    margin-top: 26pt;
    top: 20%;
    left: 55%;
    color: #F0F0F0;
    font-size: 12pt;
}

.home {
    padding: 8px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;  
}
